<template>
  <div
    class="text-sm px-4 py-2 text-white font-semibold flex justify-between"
    :class="[trialBgColor]"
  >
    <div>{{ trialText }}</div>
    <div>
      <div class="hover:underline" role="button" @click.stop="showPaymentModal">
        Upgrade Now
      </div>
    </div>
  </div>
</template>

<script>
import differenceInDays from "date-fns/differenceInDays";

export default {
  name: "TrialAlert",

  props: {
    websiteId: {
      type: String,
      default: ""
    },
    createdAt: {
      type: Date,
      default: null
    }
  },

  computed: {
    firstWebsite() {
      return this.$store.state.firstWebsite;
    },
    isFirstWebsite() {
      return this.firstWebsite === this.websiteId;
    },
    daysDifference() {
      const currentDate = Date.now();
      const daysDifference = differenceInDays(currentDate, this.createdAt);
      return 7 - daysDifference;
    },
    showTrialBadge() {
      return this.isFirstWebsite && this.daysDifference > 0;
    },
    trialText() {
      if (this.daysDifference <= 7 && this.daysDifference >= 3) {
        return `Free trial ends in ${this.daysDifference} days`;
      }
      if (this.daysDifference === 2) {
        return "Free trial ends tomorrow";
      }
      return "Free trial ends today";
    },
    trialBgColor() {
      if (this.daysDifference <= 7 && this.daysDifference >= 5) {
        return "bg-green-500";
      }
      if (this.daysDifference <= 4 && this.daysDifference >= 2) {
        return "bg-yellow-500";
      }
      return "bg-red-500";
    }
  },

  methods: {
    showPaymentModal() {
      this.$emit("upgrade");
    }
  }
};
</script>
