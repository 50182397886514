<template>
  <div
    class="overflow-hidden border rounded-md p-2 hover:shadow-lg transition-shadow duration-500 ease-in-out"
    :class="{ 'shadow-lg': selected }"
  >
    <div class="px-2 py-2">
      <div class="flex items-end">
        <div class="text-gray-500 text-xl font-medium">
          {{ title }}
        </div>
        <div class="leading-none text-gray-500 text-sm font-medium ml-1 mb-1">
          / Per website
        </div>
      </div>
      <p v-if="originalPrice" class="line-through text-red-300">
        {{ formattedOriginal }}
      </p>
      <p
        class="text-pasha text-2xl font-extrabold"
        :class="{ 'mt-6': !originalPrice }"
      >
        {{ formattedTotal }}
      </p>
      <div class="flex-wrap"></div>
      <div class="flex justify-center mt-6">
        <div
          v-show="!selected"
          class="bg-gray-900 hover:bg-white outline-none hover:text-black border hover:border-black text-white focus:bg-white focus:text-black py-2 px-4 rounded-md shadow"
          role="button"
          @click="selectPlan"
        >
          <div class="w-auto -mx-2 md:mx-0">
            Select
          </div>
        </div>
        <div
          v-show="selected"
          class="bg-white outline-none text-black border border-black focus:bg-white focus:text-black py-2 px-4 rounded-md shadow"
          role="button"
        >
          <div class="w-auto -mx-2 md:mx-0">
            Selected
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanCard",

  props: {
    originalPrice: {
      type: Number,
      default: 0
    },
    selected: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    total: {
      type: Number,
      default: 0
    }
  },

  computed: {
    countryCode() {
      return this.$store.state.countryCode;
    },
    isMexico() {
      return this.countryCode === "MX";
    },
    currencyCode() {
      if (this.isMexico) {
        return "mxn";
      }
      return "usd";
    },
    moneyFormat() {
      if (this.isMexico) {
        return new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN"
        });
      }
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      });
    },
    formattedOriginal() {
      return this.originalPrice
        ? this.moneyFormat
            .format(this.originalPrice / 100)
            .concat(" ", this.currencyCode)
        : "";
    },
    formattedTotal() {
      return this.moneyFormat
        .format(this.total / 100)
        .concat(" ", this.currencyCode);
    }
  },

  methods: {
    selectPlan() {
      this.$emit("selected");
    }
  }
};
</script>
